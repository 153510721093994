import moment from "moment";
import { getJsDateFromExcel } from "../../../../utilities/helper";

export async function formatBulkSoData(workbook: any, dropdownData: any) {
  const dropdownDataMap: any = await getDropdownDataMap(dropdownData);
  const sheetData: any[] = [];
  workbook.eachSheet((sheet: any) => {
    if (sheet.name !== "Template") return;
    sheet.eachRow((row: any, rowIndex: any) => {
      if (rowIndex > 2) {
        const rowData: any = {
          error: false,
        };

        //this is to figure out if the row has any value order if there is any value then we can push the row
        let shouldNotPushThis = true;
        row.eachCell({ includeEmpty: true }, (cell: any, colNumber: any) => {
          try {
            //checking if row has any value if it has any value then we can push the row
            if (shouldNotPushThis && cell?.text) shouldNotPushThis = false;

            //Fetching header name for current column
            const header: any = sheet.getRow(2).getCell(colNumber).value;

            let value =
              cell.value && typeof cell.value === "object"
                ? cell.text
                : cell.value;

            const data = bulkSoValidateCell(
              header,
              value,
              dropdownDataMap,
              row
            );

            rowData[data.key] = {
              value: data.value,
              data: data.data,
              error: data.error,
            };
            if (data.error) {
              rowData.error = true;
            }
          } catch (error) {
            console.error(error);
          }
        });

        if (!shouldNotPushThis) {
          sheetData.push(rowData);
        }
      }
    });
  });
  const mergeData = mergeByBuyerPONumber(sheetData);
  return validateDependencies(mergeData);
}

const validateDependencies = (data: any) => {
  data?.map((item: any) => {
    const itemNameSet: any = new Set();
    item.salesOrderItems?.map((salesOrderItem: any) => {
      if (
        salesOrderItem?.name?.value &&
        itemNameSet.has(salesOrderItem.name?.value)
      ) {
        item.error = true;
        salesOrderItem.name.error = `Item name ${salesOrderItem.name?.value} is already added for this sales order.`;
      } else {
        itemNameSet.add(salesOrderItem.name?.value);
      }
    });
  });
  return data;
};

const mergeByBuyerPONumber = (data: any) => {
  try {
    const dataMap: any = {};
    data?.map((item: any) => {
      const buyerPoNumber: any = item.buyerPoNumber?.value;
      if (!buyerPoNumber) {
        dataMap[Math.random()] = item;
      } else if (dataMap[buyerPoNumber]) {
        dataMap[buyerPoNumber].salesOrderItems.push({
          name: item.name,
          quantity: item.quantity,
          rate: item.rate,
          tax: item.tax,
          unit: item.unit,
          category: item.category,
          subCategory: item.subCategory,
          hsnCode: item.hsnCode,
          taxPercentage: item.taxPercentage,
        });
        if (item.isError) {
          dataMap[buyerPoNumber] = true;
        }
      } else {
        const salesOrderItems: any = [
          {
            name: item.name,
            quantity: item.quantity,
            rate: item.rate,
            tax: item.tax,
            unit: item.unit,
            category: item.category,
            subCategory: item.subCategory,
            hsnCode: item.hsnCode,
            taxPercentage: item.taxPercentage,
          },
        ];
        dataMap[buyerPoNumber] = item;
        dataMap[buyerPoNumber].salesOrderItems = salesOrderItems;
      }
    });
    return Object.values(dataMap);
  } catch (error) {
    console.log(error);
  }
};

const getDropdownDataMap = async (data: any) => {
  const dataMap: any = {
    "Customer Name": {},
    Branch: {},
    Merchandiser: {},
    Approver: {},
    "Payment terms": {},
    "Delivery Type": {},
    "Product Type": {},
    Currency: {},
    Name: {},
    Unit: {},
    Category: {},
    Tax: {},
  };
  data?.buyers?.map(
    (buyer: any) =>
      (dataMap["Customer Name"][buyer.name?.toString()?.toLowerCase()] = buyer)
  );
  data?.branches?.map(
    (branch: any) =>
      (dataMap.Branch[branch.name?.toString()?.toLowerCase()] = branch)
  );
  data?.user?.map((user: any) => {
    dataMap.Merchandiser[user.name?.toString()?.toLowerCase()] = user;
    dataMap.Approver[user.name?.toString()?.toLowerCase()] = user;
  });

  data?.paymentTerms?.map(
    (term: any) =>
      (dataMap["Payment terms"][term.name?.toString()?.toLowerCase()] = term)
  );

  data?.deliveryTypes?.map(
    (deliveryType: any) =>
      (dataMap["Delivery Type"][deliveryType.name?.toString()?.toLowerCase()] =
        deliveryType)
  );
  data?.productTypes?.map(
    (product: any) =>
      (dataMap["Product Type"][product.name?.toString()?.toLowerCase()] =
        product)
  );

  data?.currencies?.map(
    (currency: any) =>
      (dataMap.Currency[currency.name?.toString()?.toLowerCase()] = currency)
  );

  data?.items?.map(
    (item: any) => (dataMap.Name[item.name?.toString()?.toLowerCase()] = item)
  );
  data?.units?.map(
    (unit: any) => (dataMap.Unit[unit.name?.toString()?.toLowerCase()] = unit)
  );
  data?.categories?.map((category: any) => {
    const subCategoriesMap: any = {};
    category?.subCategories?.map(
      (subCategory: any) =>
        (subCategoriesMap[subCategory.name?.toString()?.toLowerCase()] =
          subCategory)
    );
    dataMap.Category[category.name?.toString()?.toLowerCase()] = {
      ...category,
      "Sub Category": subCategoriesMap,
    };
  });
  data?.taxes?.map(
    (tax: any) => (dataMap.Tax[tax.name?.toString()?.toLowerCase()] = tax)
  );
  return dataMap;
};

export const bulkSoValidationRules: any = {
  "Customer Name": {
    type: "dropdown",
    required: true,
    key: "customerName",
    newValue: true,
    datKeys: ["id"],
  },
  Branch: {
    type: "dropdown",
    required: true,
    key: "branch",
    newValue: true,
    datKeys: ["id"],
  },
  "Buyer PO Number": { required: true, key: "buyerPoNumber", type: "string" },
  Reference: { required: true, key: "reference", type: "string" },
  "Expected Margin %": {
    required: true,
    key: "expectedMargin",
    type: "number",
    max: 100,
    min: 0,
  },
  Merchandiser: {
    type: "dropdown",
    required: true,
    key: "merchandiser",
    newValue: true,
    datKeys: ["id"],
  },
  Approver: {
    type: "dropdown",
    required: true,
    key: "approver",
    newValue: true,
    datKeys: ["id"],
  },
  "SO Date": { type: "date", required: true, key: "soDate", maxDate: moment() },
  "Expected Date": {
    type: "date",
    required: true,
    key: "expectedDate",
    minDate: moment(),
    datKeys: ["id"],
  },
  "Payment terms": {
    type: "dropdown",
    required: true,
    key: "paymentTerms",
    newValue: true,
    datKeys: ["id"],
  },
  "Delivery Type": {
    type: "dropdown",
    required: true,
    key: "deliveryType",
    newValue: true,
    datKeys: ["id"],
  },
  "Product Type": {
    type: "dropdown",
    required: true,
    key: "productType",
    newValue: true,
    datKeys: ["id"],
  },
  Currency: {
    type: "dropdown",
    required: true,
    key: "currency",
    newValue: false,
    datKeys: ["id", "code"],
  },
  Name: {
    type: "dropdown",
    required: true,
    key: "name",
    newValue: true,
    datKeys: ["id"],
  },
  Unit: {
    type: "dropdown",
    required: true,
    key: "unit",
    newValue: true,
    datKeys: ["id"],
  },
  "HSN Code": { type: "string", required: true, key: "hsnCode" },
  Category: {
    type: "dropdown",
    required: true,
    key: "category",
    newValue: true,
    datKeys: ["id"],
  },
  "Sub Category": {
    type: "dropdown",
    required: true,
    key: "subCategory",
    newValue: true,
    datKeys: ["id"],
  },
  Quantity: { type: "number", required: true, min: 1, key: "quantity" },
  Rate: { type: "number", required: true, min: 1, key: "rate" },
  Tax: {
    type: "dropdown",
    required: true,
    key: "tax",
    newValue: true,
    datKeys: ["id", "percentage"],
  },
  "Tax %": {
    type: "number",
    required: true,
    min: 0,
    key: "taxPercentage",
    max: 100,
    default: 0,
  },
  "Discount %": {
    type: "number",
    required: false,
    default: 0,
    min: 0,
    max: 100,
    key: "discount",
  },
  "Shipment Charges": {
    type: "number",
    required: false,
    default: 0,

    key: "shipmentCharges",
  },
  "Customer Note": { type: "string", required: false, key: "customerNote" },
  TnCs: { type: "string", required: false, key: "tnc" },
  Attachments: { required: false },
};

const bulkSoValidateCell = (
  key: any,
  cellValue: any,
  dropdownDataMap: any,
  row: any
) => {
  let error = null;

  const rules = bulkSoValidationRules[key];

  let value = null;
  let data = null;

  //parsing values
  switch (rules?.type) {
    case "dropdown":
      if (cellValue) {
        value = cellValue?.toString() || "";
        if (!value) error = `${key} is invalid`;

        let dropdownData =
          dropdownDataMap?.[key]?.[cellValue?.toString()?.toLowerCase()];
        if (rules.key === "subCategory") {
          dropdownData =
            dropdownDataMap?.["Category"]?.[
              row?.getCell("Q").text?.toString()?.toLowerCase()
            ]?.["Sub Category"]?.[cellValue.toLowerCase()];
        }
        data = {};
        rules.datKeys.map((key: any) => {
          data[key] = dropdownData?.[key];
        });
      } else if (rules.required) {
        error = `${key} is required`;
      }
      break;
    case "date":
      if (cellValue) {
        value = getJsDateFromExcel(cellValue);
        if (
          value &&
          rules.minDate &&
          moment(value, "DD/MM/YYYY").isBefore(rules.minDate)
        ) {
          error = `${key} should be greater than ${rules.minDate.format(
            "DD/MM/YYYY"
          )}`;
        }
        if (
          value &&
          rules.maxDate &&
          moment(value, "DD/MM/YYYY").isAfter(rules.maxDate)
        ) {
          error = `${key} should be less than ${rules.maxDate.format(
            "DD/MM/YYYY"
          )}`;
        } else if (!value) {
          error = `${key} is invalid`;
        }
      } else if (rules.required) {
        error = `${key} is required`;
      }
      break;
    case "number":
      if (typeof cellValue === "number" || typeof cellValue === "string") {
        value = parseFloat(cellValue?.toString()) || cellValue || rules.default;
        if (typeof value !== "number") error = `${key} is invalid`;
        if (rules.max && value > rules.max) {
          error = `${key} should be less than ${rules.max}`;
        } else if (rules.min && value < rules.min) {
          error = `${key} should be greater than ${rules.min}`;
        }
      } else if (rules.required) {
        error = `${key} is required`;
      }
      break;
    case "string":
      if (cellValue) {
        value = cellValue?.toString();
      } else if (rules.required) {
        error = `${key} is required`;
      }
      break;
    default:
      break;
  }

  return {
    value: value ?? rules.default ?? null,
    data,
    error: typeof value === "number" ? null : error,
    key: rules?.key,
  };
};
